@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  font-family: "Poppins", sans-serif;
  background-color: #1c1d33;
  min-width: 320px;
}

img {
  pointer-events: none;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

/* ScrollBar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background: rgba(238, 238, 238, 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #1ce3c7;
}
/* ScrollBar */

/* bg animated */
.bgEasy {
  position: relative;
}

.bgEasy::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.02;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("../public/illustrations/background.gif");
  z-index: -1;
}

.bgEasyLogo {
  position: relative;
}

@media (min-width: 1024px) {
  .bgEasyLogo::before {
    content: "";
    position: absolute;
    top: -30%;
    left: 85%;
    transform: translate(-50%, -50%);
    transform: rotate(30deg);
    width: 100px;
    height: 100px;
    opacity: 0.2;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("../public/logo/noBgNoText.png");
    z-index: -1;
    animation: growBackground 10s ease-in-out infinite;
  }
}

/* Definindo a animação */
@keyframes growBackground {
  0% {
    background-size: 100%;
    opacity: 0.2;
  }
  50% {
    background-size: 102%;
    opacity: 0.6;
  }
  100% {
    background-size: 100%;
    opacity: 0.2;
  }
}

/* bg animated */

/* apex charts */
.apexcharts-tooltip-text-y-label,
.apexcharts-tooltip-text-y-value {
  margin-left: 0 !important;
  font-family: "Poppins", sans-serif !important;
  color: white !important;
  font-weight: bold !important;
}

.apexcharts-tooltip-title {
  margin-left: 0 !important;
  font-family: "Poppins", sans-serif !important;
  color: #525659 !important;
  font-weight: bold !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  /* border: none !important; */
  background: #181c3c !important;
}

.apexcharts-text tspan {
  font-family: "Poppins", sans-serif !important;
  font-weight: bold !important;
  font-size: 10px;
}

/* Causava erros no mobile */
/* .apexcharts-legend-series {
  pointer-events: none;
} */

.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
  font-family: "Poppins", sans-serif !important;
  color: white !important;
  font-weight: semibold !important;
}

.apexcharts-xaxistooltip {
  display: none !important;
}

.apexcharts-tooltip-series-group.apexcharts-active {
  padding-bottom: 0px !important;
}
/* apex charts */
